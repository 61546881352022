import React, {useEffect, useState} from 'react'

import Layout from '../../../layouts/Layout';
import Footer from '../../../layouts/Footer';
import SmoothScroll from '../../SmoothScroll'

import Hero from './Hero'
import HouseVideo from './HouseVideo'
import Partners from './Partners'
import WorkShowcase from './WorkShowcase'
import OurYourHouse from './OurYourHouse'

const Home = () => {
    const [scrollHeight, setScrollHeight] = useState(0)
    useEffect(() => {
        const stateToggler = () => setScrollHeight(window.scrollY)
        window.addEventListener('scroll', stateToggler)
        return () => window.removeEventListener('scroll', stateToggler)
    }, [])

    return (
        <Layout pageClass="home-wrapper" hideFooter>
            <Hero scrollHeight={scrollHeight} setScrollHeight={setScrollHeight}/>
            <SmoothScroll>
                <HouseVideo/>
                <OurYourHouse/>
                <WorkShowcase/>
                <Partners/>
                <Footer/>
            </SmoothScroll>
        </Layout>
    )
}

export default Home
