import React, { useEffect } from 'react';
import {useLocation} from '@reach/router';
import {gsap, ScrollTrigger} from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const SmoothScroll = ({ children }) => {
    const location = useLocation()

    const data = {
        ease: 0.1,
        current: 0,
        previous: 0,
        rounded: 0
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
            const wrap = document.querySelector('.smooth-scroller')
            const scrollWrap = document.querySelector('.smooth-scroll')
            if (location.pathname !== '/*' && wrap && scrollWrap) {
                const setBodyHeight = () => {
                    if (typeof window !== 'undefined') {
                        document.body.style.height = `${
                            wrap.clientHeight
                        }px`;
                    }
                };
                setBodyHeight()
                window.addEventListener('scroll', setBodyHeight)
                const smoothScrollingHandler = () => {
                    data.current = window.scrollY;
                    data.previous += (data.current - data.previous) * data.ease;
                    data.rounded = Math.round(data.previous * 100) / 100;
                    ScrollTrigger.update()
                    wrap.style.transform = `translateY(-${data.previous}px)`;
                    // Recursive call
                    requestAnimationFrame(() => smoothScrollingHandler());
                };
                requestAnimationFrame(() => smoothScrollingHandler());
                return () => window.removeEventListener('scroll', setBodyHeight)
            }
        }

    }, [location]);

    return (
        <div className="smooth-scroll">
            <div className='smooth-scroller'>{children}</div>
        </div>
    );
};

export default SmoothScroll;
